export enum PaymentProvider {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  BRAINTREE = 'braintree',
  PAYPAL_CHECKOUT = 'paypal_checkout',
}

export interface PaymentData {
  paymentProvider: PaymentProvider;
  payload?: Record<string, unknown>;
  method?: string;
}

export interface OrderInterface extends PaymentData {
  id: string;
}

interface PaymentError extends PaymentData {
  code?: string;
  message?: string;
}

export interface PaymentOptions {
  currency?: string;
  amount?: number;
  title?: string;
  locale?: string;
  isSubscription?: boolean;
}

export interface PaymentsConfig {
  createOrder: (
    provider: Omit<OrderInterface, 'id'>,
  ) => Promise<{ id: string; subscription_id?: string } & unknown> | { id: string };
  onSuccess: (order: OrderInterface) => Promise<void> | void;
  onSubmit?: (data: { paymentProvider: PaymentProvider }) => Promise<boolean> | void;
  onCancel?: (order: Omit<OrderInterface, 'id'>) => void;
  onError?: (error?: { code?: string }) => void;
  forcePPRerender?: unknown[];
  options?: PaymentOptions;
}

export interface Callbacks {
  createOrder: (
    order: PaymentData,
  ) => Promise<{ id: string; status?: string } | { error: { code: 'missing_payment_method' } }>;
  onSubmit?: (data: PaymentData) => void;
  onError?: (data: PaymentError) => void;
  onSuccess?: (order: OrderInterface) => void;
}

export interface CallbacksWithOptions extends Callbacks {
  options?: PaymentOptions;
}

export interface PaymentProviderInterface extends PaymentsConfig {
  onProviderSuccess: (order: OrderInterface) => void;
}

export type StripeFormSubmitEvent = { cardElementName: 'cardNumber'; billingName?: string };
