import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { OrderInterface, PaymentProvider } from '../types/general';

interface StripeCustomFormProps {
  clientSecret: string;
  onSuccess: (order: OrderInterface) => void;
}

let isSubmited = false;

export const StripeCustomForm = ({ clientSecret, onSuccess }: StripeCustomFormProps) => {
  const elements = useElements();
  const stripe = useStripe();
  const fullNameRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const cardExpiration = elements?.create('cardExpiry', { style: { base: { fontSize: '16px' } } });
    const cvcElement = elements?.create('cardCvc', { style: { base: { fontSize: '16px' } } });
    const cardNumber = elements?.create('cardNumber', { style: { base: { fontSize: '16px' } } });

    cvcElement?.mount('#stripe_card_cvc');
    cardNumber?.mount('#stripe_card_number');
    cardExpiration?.mount('#stripe_card_expiration');
  }, [elements]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    const cardNumberElement = elements?.getElement('cardNumber');
    if (elements && stripe && cardNumberElement && !isSubmited) {
      isSubmited = true;
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: fullNameRef?.current?.value,
          },
        },
      });

      if (response?.paymentIntent?.status === 'succeeded') {
        onSuccess({
          id: response.paymentIntent.id,
          paymentProvider: PaymentProvider.STRIPE,
        });
      } else if (response.error) {
        setError(`${response.error.message} Please try again.`);
        setIsLoading(false);
      }
      isSubmited = false;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Text color='#CC4C4C'>{error}</Text>}
      <Box
        as='input'
        ref={fullNameRef}
        type='text'
        placeholder='Full name'
        required
        width='100%'
        outline='none'
        marginBottom='0.625rem'
        border='1px solid rgba(0, 0, 0, 0.1)'
        borderRadius='0.5rem'
        fontSize='16px'
        color='#000000'
        padding='0.75rem 1rem'
        boxShadow='none'
        _placeholder={{
          color: '#757575',
        }}
      />
      <Box padding='0.75rem 1rem' border='1px solid rgba(0, 0, 0, 0.1)' borderRadius='0.5rem' marginBottom='0.625rem'>
        <div id='stripe_card_number' />
      </Box>
      <Flex gap='0.625rem' marginBottom='0.625rem'>
        <Box flex='1' padding='0.75rem 1rem' border='1px solid rgba(0, 0, 0, 0.1)' borderRadius='0.5rem'>
          <div id='stripe_card_expiration' />
        </Box>
        <Box flex='1' padding='0.75rem 1rem' border='1px solid rgba(0, 0, 0, 0.1)' borderRadius='0.5rem'>
          <div id='stripe_card_cvc' />
        </Box>
      </Flex>
      <Button
        type='submit'
        isLoading={isLoading}
        width='100%'
        borderRadius='0.5rem'
        background='#113FE3'
        _hover={{ backgroundColor: '#0f37c9' }}
        fontWeight={600}
        fontSize='16px'
        padding='1rem 1.5rem'
      >
        Submit secure payment
      </Button>
    </form>
  );
};
