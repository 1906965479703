import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { memo, useState } from 'react';
import { PaymentProvider, PaymentsConfig } from '../types/general';

const PP_CLIENT = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string;

const PP_LOADING_BG = '#ffd269';
const PP_SPINNER_COLOR = '#0E3282';

export const PaypalForm = memo(({ options, forcePPRerender, createOrder, onSuccess, onSubmit }: PaymentsConfig) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const createMethod = options?.isSubscription
    ? {
        createSubscription: async (_: any, actions: unknown) => {
          setIsLoading(true);
          const planConfig = await createOrder({ paymentProvider: PaymentProvider.PAYPAL });
          if (onSubmit) {
            onSubmit({ paymentProvider: PaymentProvider.PAYPAL });
          }

          // @ts-ignore
          return actions.subscription.create(planConfig);
        },
      }
    : {
        createOrder: async (): Promise<string> => {
          setIsLoading(true);

          const order = await createOrder({ paymentProvider: PaymentProvider.PAYPAL });
          if (onSubmit) {
            onSubmit({ paymentProvider: PaymentProvider.PAYPAL });
          }

          return order.id as string;
        },
      };

  return PP_CLIENT ? (
    <PayPalScriptProvider
      options={{
        'client-id': PP_CLIENT,
        vault: true,
        currency: options?.currency,
      }}
    >
      <Box>
        {error && (
          <Text color='#e12a2a' fontSize='14px' marginBottom='0.5rem'>
            {error}
          </Text>
        )}
        <Box position='relative' width='100%'>
          <PayPalButtons
            {...createMethod}
            forceReRender={forcePPRerender}
            onApprove={async (data, actions) => {
              try {
                if (data.orderID && !data.subscriptionID) {
                  await actions.order?.capture();
                }

                await onSuccess({ id: data.subscriptionID || data.orderID, paymentProvider: PaymentProvider.PAYPAL });
              } catch (err) {
                setError('Ups, something went wrong! Please try again.');
              }
              setIsLoading(false);
            }}
            onCancel={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setError('Ups, something went wrong! Please try again.');
              setIsLoading(false);
            }}
            style={{ layout: 'horizontal', tagline: false }}
          />
          {isLoading && (
            <Flex
              position='absolute'
              backgroundColor={PP_LOADING_BG}
              top='0'
              right='0'
              left='0'
              bottom='0'
              zIndex='9999'
              alignItems='center'
              justifyContent='center'
              borderRadius='4px'
            >
              <Spinner size='sm' color={PP_SPINNER_COLOR} />
            </Flex>
          )}
        </Box>
      </Box>
    </PayPalScriptProvider>
  ) : null;
});

PaypalForm.displayName = 'PaypalForm';
