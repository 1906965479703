import { FirebaseFunc, ProductBase } from '@shared/common';
import { Functions, httpsCallable } from 'firebase/functions';
import { OrderInterface, PaymentProvider } from '../types/general';
import { useCallback } from 'react';

interface OrderRequest {
  leadId: string;
  planId: string;
  provider: PaymentProvider;
}

interface OrderResponse {
  id: string;
}

export const useOrder = <Product extends ProductBase>(
  firebaseFunctions: Functions,
  leadId?: string,
  product?: Product,
) => {
  const createOrder = async (orderDetails: Omit<OrderInterface, 'id'>): Promise<{ id: string } & unknown> => {
    const order = await await httpsCallable<OrderRequest, OrderResponse>(
      firebaseFunctions,
      FirebaseFunc.CREATE_ORDER,
    )({
      leadId: leadId || '',
      planId: product?.id || '',
      provider: orderDetails.paymentProvider,
    });

    return order.data;
  };

  const storeOrder = useCallback(
    async (order: OrderInterface) => {
      if (order.paymentProvider === PaymentProvider.STRIPE && !product?.isSubscription) {
        await (
          await httpsCallable<OrderRequest & { orderId: string }>(firebaseFunctions, FirebaseFunc.STRIPE_SAVE_ORDER)
        )({
          leadId: leadId || '',
          planId: product?.id || '',
          provider: order.paymentProvider,
          orderId: order.id,
        });
      }
    },
    [leadId, product],
  );

  return {
    createOrder,
    storeOrder,
  };
};
