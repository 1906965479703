import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { PaymentsConfig } from '../types/general';
import { Stripe } from '../providers/Stripe';
import { Paypal } from '../providers/Paypal';

export const InlinePaymentsLayout: FC<PaymentsConfig> = memo((props) => {
  return (
    <Box id='payments_component' maxWidth='30rem'>
      <Paypal {...props} />
      <Flex margin='1rem 0' gap='1rem' alignItems='center'>
        <Box flex='1' height='1px' backgroundColor='#ebebeb' />
        <Text color='#565656' fontSize='14px'>
          OR
        </Text>
        <Box flex='1' height='1px' backgroundColor='#ebebeb' />
      </Flex>
      <Stripe {...props} />
    </Box>
  );
});

InlinePaymentsLayout.displayName = 'PaymentsComponent';
