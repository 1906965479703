import { Box, Button, Flex } from '@chakra-ui/react';
import { useElements } from '@stripe/react-stripe-js';
import { FC, FormEvent, useEffect, useRef } from 'react';
import { StripeFormSubmitEvent } from '../types/general';

interface StripeFormProps {
  isLoading?: boolean;
  buttonText?: string;
  onSubmit?: (data: StripeFormSubmitEvent) => void;
}

const CARD_ELEMENT_NAME = 'cardNumber';

export const StripeForm: FC<StripeFormProps> = ({ isLoading, onSubmit, buttonText = 'Submit secure payment' }) => {
  const elements = useElements();
  const fullNameRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const cardExpiration = elements?.create('cardExpiry', { style: { base: { fontSize: '16px' } } });
    const cvcElement = elements?.create('cardCvc', { style: { base: { fontSize: '16px' } } });
    const cardNumber = elements?.create(CARD_ELEMENT_NAME, { style: { base: { fontSize: '16px' } } });

    cvcElement?.mount('#stripe_card_cvc');
    cardNumber?.mount('#stripe_card_number');
    cardExpiration?.mount('#stripe_card_expiration');
  }, [elements]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (onSubmit) {
      onSubmit({ cardElementName: CARD_ELEMENT_NAME, billingName: fullNameRef.current?.value });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        as='input'
        ref={fullNameRef}
        type='text'
        placeholder='Full name'
        required
        width='100%'
        outline='none'
        marginBottom='0.625rem'
        border='1px solid rgba(0, 0, 0, 0.1)'
        borderRadius='0.5rem'
        fontSize='16px'
        color='#000000'
        padding='0.75rem 1rem'
        boxShadow='none'
        _placeholder={{
          color: '#757575',
        }}
      />
      <Box padding='0.75rem 1rem' border='1px solid rgba(0, 0, 0, 0.1)' borderRadius='0.5rem' marginBottom='0.625rem'>
        <div id='stripe_card_number' />
      </Box>
      <Flex gap='0.625rem' marginBottom='0.625rem'>
        <Box flex='1' padding='0.75rem 1rem' border='1px solid rgba(0, 0, 0, 0.1)' borderRadius='0.5rem'>
          <div id='stripe_card_expiration' />
        </Box>
        <Box flex='1' padding='0.75rem 1rem' border='1px solid rgba(0, 0, 0, 0.1)' borderRadius='0.5rem'>
          <div id='stripe_card_cvc' />
        </Box>
      </Flex>
      <Button
        type='submit'
        isLoading={isLoading}
        width='100%'
        borderRadius='0.5rem'
        background='#113FE3'
        _hover={{ backgroundColor: '#0f37c9' }}
        fontWeight={600}
        fontSize='16px'
        padding='1rem 1.5rem'
      >
        {buttonText}
      </Button>
    </form>
  );
};
