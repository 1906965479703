import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  Radio,
  Image,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import PaypalLogo from '@shared/assets/general/paypal_logo.png';
import CardsLogo from '@shared/assets/general/accept_cards.png';
import { useTranslation } from './hooks/useTranslation';
import { PaymentsConfig } from './types/general';
import { Stripe } from './providers/Stripe';
import { PaypalForm } from './forms/PaypalForm';
import SecurePaymentsImage from '@shared/assets/general/secure_payments.png';

export const PaymentsComponent: FC<PaymentsConfig> = (props) => {
  const t = useTranslation(props?.options?.locale);
  const [active, setActive] = useState('card');

  return (
    <Box id='payments_component' maxWidth='30rem' margin='0 auto' width='100%'>
      <Accordion defaultIndex={[0]}>
        <AccordionItem marginBottom='1rem' backgroundColor='white' borderRadius='0.25rem'>
          <AccordionButton
            gap='0.5rem'
            padding='1rem'
            backgroundColor='white'
            borderRadius='0.5rem'
            border='1px solid #dadad0'
            _expanded={{ borderRadius: '0.5rem 0.5rem 0 0', borderBottom: 'none' }}
            onClick={() => setActive('card')}
            cursor='pointer'
          >
            <Radio isChecked={active === 'card'} />
            <Text color='system.black' fontSize='14px' fontWeight={600}>
              {t('credit_card')}
            </Text>
            <Flex flex='1' justifyContent='right'>
              <Box as='img' src={CardsLogo.src} height='16px' alt='' />
            </Flex>
          </AccordionButton>
          <AccordionPanel padding='1rem' border='1px solid #dadad0' borderRadius='0 0 0.5rem 0.5rem'>
            <Stripe {...props} />
            <Flex marginTop='1.25rem'>
              <Image src={SecurePaymentsImage.src} width={{ base: '50%', md: '40%' }} margin='auto' />
            </Flex>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem marginBottom='1rem' backgroundColor='white' borderRadius='0.25rem'>
          <AccordionButton
            gap='0.5rem'
            padding='1rem'
            backgroundColor='white'
            borderRadius='0.5rem'
            border='1px solid #dadad0'
            _expanded={{ borderRadius: '0.5rem 0.5rem 0 0', borderBottom: 'none' }}
            onClick={() => setActive('paypal')}
            cursor='pointer'
          >
            <Radio isChecked={active === 'paypal'} />

            <Text color='system.black' fontSize='14px' fontWeight={600}>
              PayPal
            </Text>
            <Flex flex='1' justifyContent='right'>
              <Box as='img' src={PaypalLogo.src} height='16px' alt='' />
            </Flex>
          </AccordionButton>
          <AccordionPanel padding='1rem' border='1px solid #dadad0' borderRadius='0 0 0.5rem 0.5rem'>
            <PaypalForm {...props} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

PaymentsComponent.displayName = 'PaymentsComponent';
