import { Currency, Locale } from '@shared/common';
import { PaymentsComponent } from './PaymentsComponent';
import { Paypal } from './providers/Paypal';
import { PaypalCheckout } from './providers/PaypalCheckout';
import { Stripe } from './providers/Stripe';
import { StripeCustomForm } from './providers/StripeCustomForm';
import { StripeCustomForm as LegacyStripeCustomForm } from './forms/StripeCustomForm';
import { StripeElement } from './providers/StripeElement';
import { StripeWrapper } from './StripeWrapper';
import { PaymentsConfig } from './types/general';
import { PaymentButtons } from './forms/PaymentButtons';

const DEFAULT_OPTIONS: PaymentsConfig['options'] = {
  currency: Currency.USD,
  locale: Locale.EN,
};

export const CheckoutComponent = (props: PaymentsConfig) => {
  const options = {
    ...DEFAULT_OPTIONS,
    ...props.options,
  };

  return (
    <StripeWrapper locale={options.locale}>
      <PaymentsComponent {...props} options={options} />
    </StripeWrapper>
  );
};

CheckoutComponent.displayName = 'CheckoutComponent';

export const PaymentElement = {
  Paypal: Paypal,
  Stripe: Stripe,
  StripeElement: StripeElement,
  StripeCustomForm: StripeCustomForm,
  LegacyStripeCustomForm: LegacyStripeCustomForm,
  PaypalCheckout: PaypalCheckout,
  PaymentButtons: PaymentButtons,
};

export { StripeWrapper } from './StripeWrapper';
export { useOrder } from './hooks/useOrder';
export { type OrderInterface, PaymentProvider, type PaymentData } from './types/general';
export { InlinePaymentsLayout } from './layouts/InlinePaymentsLayout';
export { PaymentsAccordion } from './layouts/PaymentsAccordion';

export { StripeCreatePaymentMethod } from './forms/StripeCreatePaymentMethod';
export * from './braintree';
