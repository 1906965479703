import { Box, Text } from '@chakra-ui/react';
import { FormEvent, ReactNode, useRef } from 'react';
import { SubmitButton } from '../components/SubmitButton';
import { useTranslation } from '../hooks/useTranslation';

let isSubmited = false;

export const StripeCardForm = ({
  onSubmit,
  isLoading,
  children,
  locale,
  error,
}: {
  isLoading: boolean;
  children: ReactNode;
  locale?: string;
  error?: string;
  onSubmit: (data: { name: string }) => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const t = useTranslation(locale);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isSubmited) {
      isSubmited = true;

      await onSubmit({ name: inputRef?.current?.value || '' });

      isSubmited = false;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <Text color='#e12a2a' fontSize='14px' marginBottom='1rem'>
          {error}
        </Text>
      )}
      <Box
        ref={inputRef}
        as='input'
        required
        width='100%'
        padding='0.75rem 1rem'
        fontSize='14px'
        type='text'
        name='name'
        marginBottom='0.75rem'
        border='1px solid'
        borderColor='#F3EEE8'
        borderRadius='0.25rem'
        placeholder={t('full_name')}
        fontWeight={400}
        outline='none'
        _focusVisible={{
          borderColor: '#F3EEE8',
          borderRadius: '0.25rem',
        }}
        backgroundColor='system.white'
      />
      <Box
        padding='1rem'
        marginBottom='0.75rem'
        border='1px solid'
        borderColor='#F3EEE8'
        backgroundColor='#fff'
        borderRadius='0.25rem'
      >
        {children}
      </Box>
      <SubmitButton isLoading={isLoading} />
    </form>
  );
};
