import { Text } from '@chakra-ui/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { FC, useState } from 'react';
import { StripeFormSubmitEvent } from '../types/general';
import { StripeForm } from './StripeForm';

export const StripeCreatePaymentMethod: FC<{ onSuccess: (data: { paymentMethodId: string }) => void }> = ({
  onSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (data: StripeFormSubmitEvent) => {
    const cardNumberElement = elements?.getElement(data.cardElementName);

    if (stripe && cardNumberElement) {
      setIsLoading(true);
      const res = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: { name: data.billingName },
      });

      if (res?.paymentMethod?.id) {
        onSuccess({
          paymentMethodId: res.paymentMethod.id,
        });
      } else {
        setError(res.error?.message || 'Oops, something went wrong, please try again.');
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      {error ? <Text color='#CC4C4C'>{error}</Text> : null}
      <StripeForm isLoading={isLoading} onSubmit={handleFormSubmit} buttonText='Update payment method' />
    </>
  );
};
