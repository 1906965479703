import { PaymentProvider, PaymentsConfig } from '../types/general';
import braintree from 'braintree-web';
import { useEffect, useState } from 'react';
import { Currency } from '@shared/common';
import { Flex, Spinner, Text } from '@chakra-ui/react';

const BUTTON_CONTAINER = 'paypal-checkout-button';

type PaypalCheckoutProps = Omit<PaymentsConfig, 'createOrder'> & {
  onInit: () => Promise<{ id: string }>;
  onApprove: (data: { paymentMethodNonce: string; deviceData: string }) => Promise<{ id: string }>;
  options: {
    amount: number;
    leadId?: string;
  };
};

export const PaypalCheckout = ({ options, ...paymentConfig }: PaypalCheckoutProps) => {
  const [error, setError] = useState('');
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const currency = options?.currency || Currency.USD;
  const amount = options?.amount || 0;

  const renderPaypalCheckoutButton = async () => {
    setStatus('loading');
    const initResponse = await paymentConfig.onInit();

    braintree.client.create({ authorization: initResponse.id }, (err: unknown, clientInstance: braintree.Client) => {
      if (err) {
        console.error(err);
        setError('Oops, something went wrong! Please try again or use another payment method instead');
        setStatus('error');
        return;
      }

      braintree.paypalCheckout.create(
        { client: clientInstance },
        (err?: braintree.BraintreeError, paypalCheckoutInstance?: braintree.PayPalCheckout) => {
          if (err) {
            console.error(err);
            setError('Oops, something went wrong! Please try again or use another payment method instead');
            setStatus('error');
            return;
          }

          paypalCheckoutInstance?.loadPayPalSDK(
            { 'disable-funding': 'card,credit', intent: 'capture', currency, vault: true },
            () => {
              setStatus('success');
              // @ts-ignore
              return paypal
                .Buttons({
                  [options.isSubscription ? 'createBillingAgreement' : 'createOrder']: async () => {
                    return await paypalCheckoutInstance.createPayment({
                      amount,
                      currency,
                      // @ts-ignore
                      flow: options.isSubscription ? 'vault' : 'checkout',
                      // @ts-ignore
                      intent: 'capture',
                      requestBillingAgreement: true,
                    });
                  },
                  onApprove: async (data) => {
                    const tokenizedPayment = await paypalCheckoutInstance.tokenizePayment(data);
                    const { deviceData } = await braintree.dataCollector.create({ client: clientInstance });

                    const orderResponse = (await paymentConfig.onApprove({
                      paymentMethodNonce: tokenizedPayment.nonce,
                      deviceData,
                    })) as unknown as {
                      status: 'settled' | 'settling';
                      links: Array<{ rel: string; href: string }>;
                    };

                    if (orderResponse.status === 'settled' || orderResponse.status === 'settling') {
                      await paymentConfig.onSuccess({
                        // @ts-ignore
                        id: data.orderID as string,
                        paymentProvider: PaymentProvider.PAYPAL_CHECKOUT,
                      });
                    } else {
                      setError('Something went wrong. Please try again or use different payment method.');
                    }

                    return tokenizedPayment;
                  },
                })
                .render(`#${BUTTON_CONTAINER}`);
            },
          );
        },
      );
    });
  };

  useEffect(() => {
    renderPaypalCheckoutButton();
  }, []);

  return (
    <>
      {status === 'loading' ? (
        <Flex height='45px' alignItems='center' justifyContent='center'>
          <Spinner color='#ffc439' />
        </Flex>
      ) : null}
      {error && (
        <Text marginBottom='0.25rem' fontSize='14px' color='red'>
          {error}
        </Text>
      )}
      <div id={BUTTON_CONTAINER} />
    </>
  );
};
