import { PaymentProvider, PaymentsConfig } from '../types/general';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { StripeCardForm } from '../forms/StripeCardForm';

let IS_SUBMITED = false;

export const Stripe = (props: PaymentsConfig) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleStripeSubmit = async ({ name }: { name: string }) => {
    if (stripe && elements && !IS_SUBMITED) {
      setError('');
      setIsLoading(true);
      IS_SUBMITED = true;

      if (props.onSubmit) {
        const shouldCancel = await props.onSubmit({ paymentProvider: PaymentProvider.STRIPE });

        if (shouldCancel && props.onCancel) {
          props.onCancel({ paymentProvider: PaymentProvider.STRIPE });

          return false;
        }
      }

      const cardEl = elements.getElement(CardElement);
      const order = await props.createOrder({
        paymentProvider: PaymentProvider.STRIPE,
      });

      if (cardEl) {
        const res = await stripe.confirmCardPayment(order.id as string, {
          payment_method: {
            card: cardEl,
            billing_details: {
              name,
            },
          },
        });

        if (res.error) {
          setError(`${res.error.message} Please try again.`);
          setIsLoading(false);
        } else {
          await props.onSuccess({
            id: res.paymentIntent.id,
            paymentProvider: PaymentProvider.STRIPE,
          });
        }

        IS_SUBMITED = false;
      }
    }
  };

  return (
    <StripeCardForm locale={props?.options?.locale} isLoading={isLoading} error={error} onSubmit={handleStripeSubmit}>
      <CardElement />
    </StripeCardForm>
  );
};
