import { constants } from '../constants';

export const useTranslation = (locale: string = 'en-US') => {
  const localeList = locale.split('-');
  const lang = localeList[0] && /^[a-z]{2}$/.test(localeList[0]) ? localeList[0] : ('en' as keyof typeof constants);

  return (key: string): string => {
    return lang && constants[lang] ? constants[lang][key] : constants['en'][key] ? constants['en'][key] : key;
  };
};
