import { FC, useState } from 'react';
import { BraintreeCheckout } from './BraintreeWrapper';
import braintree from 'braintree-web';
import { useInit } from '@shared/common';
import { Callbacks, PaymentProvider } from '../types/general';
import { ERRORS, METHODS } from '../constants';
import { ErrorMessage } from '../components/ErrorMessage';

const BUTTON_CONTAINER_ID = 'bt_paypal_checkout';

export const PaypalCheckout: FC<BraintreeCheckout & Callbacks> = ({
  options,
  client,
  paypalCheckout,
  createOrder,
  onSuccess,
  onSubmit,
  onError,
}) => {
  const [error, setError] = useState('');
  const { isSubscription } = options;

  const handleError = (message: string) => {
    setError(message);

    if (onError) {
      onError({
        paymentProvider: PaymentProvider.BRAINTREE,
        method: METHODS.paypal,
        message,
      });
    }
  };

  useInit(() => {
    const paymentType = isSubscription ? 'createBillingAgreement' : 'createOrder';

    // @ts-ignore
    paypal
      .Buttons({
        [paymentType]: async () => {
          return paypalCheckout.createPayment({
            amount: options.amount,
            currency: options.currency,
            // @ts-ignore
            flow: isSubscription ? 'vault' : 'checkout',
            // @ts-ignore
            intent: 'capture',
            requestBillingAgreement: true,
          });
        },
        onApprove: async (data) => {
          const auth = await paypalCheckout.tokenizePayment(data);
          const { deviceData } = await braintree.dataCollector.create({ client });

          const order = await createOrder({
            paymentProvider: PaymentProvider.BRAINTREE,
            method: METHODS.paypal,
            payload: {
              paymentMethodNonce: auth.nonce,
              deviceData,
            },
          });

          if ('error' in order) {
            if (order.error.code === 'missing_payment_method') {
              handleError(ERRORS.missingPaymentMethod);
            } else {
              handleError(ERRORS.generic);
            }
          } else {
            if (onSuccess && ['settled', 'settling', 'Active'].find((status) => status === order.status)) {
              onSuccess({
                id: order.id,
                paymentProvider: PaymentProvider.BRAINTREE,
                method: METHODS.paypal,
              });
            } else {
              handleError(ERRORS.missingPaymentMethod);
            }
          }

          return auth;
        },
        onClick: () => {
          if (onSubmit) {
            onSubmit({ paymentProvider: PaymentProvider.BRAINTREE, method: METHODS.paypal });
          }
        },
        onError: () => {
          handleError(ERRORS.generic);
        },
      })
      .render(`#${BUTTON_CONTAINER_ID}`);
  });

  return (
    <>
      <ErrorMessage error={error} />
      <div
        style={{ display: 'flex', height: '45px', background: '#ffc439', borderRadius: '4px' }}
        id={BUTTON_CONTAINER_ID}
      ></div>
    </>
  );
};
