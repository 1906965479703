import { OrderInterface, PaymentProvider, PaymentsConfig } from '../types/general';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FC, FormEvent, useEffect, useState } from 'react';
import { SubmitButton } from '../components/SubmitButton';
import { StripeWrapper } from '../StripeWrapper';
import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';

let IS_SUBMITED = false;

export const StripeElement = ({ createOrder, onSuccess }: PaymentsConfig) => {
  const [clientSecret, setClientSecret] = useState('');

  const handleInitialLoad = async () => {
    if (!clientSecret) {
      const data = await createOrder({ paymentProvider: PaymentProvider.STRIPE });

      setClientSecret(data.id);
    }
  };

  useEffect(() => {
    handleInitialLoad();
  }, []);

  return clientSecret ? (
    <StripeWrapper options={{ clientSecret: clientSecret }}>
      <StripePaymentElement clientSecret={clientSecret} onSuccess={onSuccess} />
    </StripeWrapper>
  ) : (
    <>
      <Skeleton width='100%' height='40px' borderRadius='0.5rem' marginBottom='1rem' />
      <Flex gap='1rem' marginBottom='1rem'>
        <Skeleton width='100%' height='40px' borderRadius='0.5rem' />
        <Skeleton width='100%' height='40px' borderRadius='0.5rem' />
      </Flex>
      <Skeleton width='100%' height='40px' borderRadius='0.5rem' />
    </>
  );
};

const StripePaymentElement: FC<{
  clientSecret: string;
  onSuccess: (order: OrderInterface) => void | Promise<void>;
}> = ({ clientSecret, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (stripe && elements && !IS_SUBMITED) {
      setError('');
      setIsLoading(true);
      IS_SUBMITED = true;

      const { error: submitError } = await elements.submit();

      if (submitError) {
        setError(`${submitError.message} Please try again or another card`);
        setIsLoading(false);
      } else {
        const response = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${window.location.origin}/checkout`,
          },
          redirect: 'if_required',
        });

        if (response.error) {
          setError(`${response.error.message} Please try again or another card`);
          setIsLoading(false);
        } else {
          await onSuccess({
            id: clientSecret,
            paymentProvider: PaymentProvider.STRIPE,
          });
        }
      }

      IS_SUBMITED = false;
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {error && (
        <Text fontSize='14px' marginBottom='0.5rem' color='#c70000' textAlign='center'>
          {error}
        </Text>
      )}
      <Box marginBottom='1rem'>
        <PaymentElement />
      </Box>
      <SubmitButton isLoading={isLoading} />
    </form>
  );
};

// const [paymentRequest, setPaymentRequest] = useState(null);

// useEffect(() => {
//   if (stripe) {
//     const pr = stripe.paymentRequest({
//       country: 'US',
//       currency: 'usd',
//       total: {
//         label: 'Demo total',
//         amount: 1099,
//       },
//       requestPayerName: true,
//     });

//     pr.canMakePayment().then((result) => {
//       if (result) {
//         // @ts-ignore
//         setPaymentRequest(pr);
//       }
//     });
//   }
// }, [stripe]);

// {paymentRequest && (
//   <Box marginBottom='1rem'>
//     <PaymentRequestButtonElement options={{ paymentRequest }} />
//   </Box>
// )}
