import { Skeleton } from '@chakra-ui/react';
import braintree from 'braintree-web';
import { FC, useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from '../components/ErrorMessage';
import { ERRORS } from '../constants';
import { PaymentOptions } from '../types/general';

export interface BraintreeCheckout {
  client: braintree.Client;
  paypalCheckout: braintree.PayPalCheckout;
  options: PaymentOptions;
}

interface BraintreeWrapper {
  fetchToken: () => Promise<{ id: string }>;
  children: (braintreeCheckout: BraintreeCheckout) => void;
  options: PaymentOptions;
}

export const BraintreeWrapper: FC<BraintreeWrapper> = ({ fetchToken, children, options }) => {
  const [error, setError] = useState('');
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [braintreeCheckout, setBraintreeCheckout] = useState<BraintreeCheckout | null>(null);
  const currency = useMemo(() => options?.currency || 'USD', [options?.currency]);

  const handleError = () => {
    setError(ERRORS.loadingIssue);
    setStatus('error');
  };

  useEffect(() => {
    const handleLoad = async () => {
      const token = await fetchToken();

      braintree.client.create({ authorization: token.id }, (clientError, client: braintree.Client) => {
        if (clientError) {
          console.error('clientError', clientError);
          handleError();
          return;
        }

        braintree.paypalCheckout.create({ client }, (paypalCheckoutError, paypalCheckout) => {
          if (paypalCheckoutError || !paypalCheckout) {
            console.error('paypalCheckoutError', paypalCheckoutError);
            handleError();
            return;
          }

          paypalCheckout.loadPayPalSDK(
            { 'disable-funding': 'card,credit', intent: 'capture', currency, vault: true },
            (sdkError) => {
              if (sdkError) {
                console.error('sdkError:', paypalCheckoutError);
                handleError();
                return;
              }

              setBraintreeCheckout({
                client,
                paypalCheckout,
                options,
              });
              setStatus('success');
            },
          );
        });
      });
    };

    handleLoad();
  }, []);

  return (
    <>
      <ErrorMessage error={error} />
      {status === 'success' && braintreeCheckout ? (
        <>{children(braintreeCheckout)}</>
      ) : status === 'loading' ? (
        <Skeleton height='45px' />
      ) : (
        <Skeleton height='45px' />
      )}
    </>
  );
};
